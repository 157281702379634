import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Layout from '../layouts'

const Desc = styled.p`
  width: 100%;
  font-family: 'aktiv-grotesk-std', 'helvetica neue', sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.2;
  line-height: 1.5;
  color: white;

  @media (min-width: 480px) {
    font-size: 18px;
  }

  @media (min-width: 760px) {
    font-size: 20px;
  }

  a {
    color: white;
    font-weight: 300;
    text-decoration: none;
    border-bottom: 1px dotted rgba(255,255,255,0.6);

    &:hover {
      border-bottom: 1px solid rgba(255,255,255,0.6);
    }
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Desc>Only have the one page right now... sorry about that. Please head on back to the <Link to="/">homepage</Link></Desc>
  </Layout>
)

export default NotFoundPage
